import React, { FC } from 'react';
import { css, cx } from 'emotion';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/logo.svg" alt="PxC" />;
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const background = css``;

  return <div className={cx(background, className)}>{children}</div>;
};

const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/signet-white.svg" alt=".io" />;
};

const LoginBoxBackground = () => {
  return css``;
};

export class Branding {
  static LoginLogo = LoginLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = 'PxC Dashboards';
  static LoginTitle = 'Dashboards';
  static GetLoginSubTitle = () => {
    return '';
  };
}
