import React, { FC } from 'react';
import { Icon, IconName } from '@grafana/ui';

export interface FooterLink {
  text: string;
  icon?: string;
  url?: string;
  target?: string;
}

export let getFooterLinks = (): FooterLink[] => {
  return [
    {
      text: 'Home',
      icon: 'home',
      url: 'https://www.phoenixcontact.com/',
      target: '_blank',
    },
    {
      text: 'Gebäudeautomation',
      icon: 'building',
      url:
        'https://www.phoenixcontact.com/online/portal/de?1dmy&urile=wcm%3apath%3a/dede/web/main/solutions/subcategory_pages/Building/39f6c6e8-7ff0-4ae5-b70d-102117708f81',
      target: '_blank',
    },
    {
      text: 'Facebook',
      icon: 'facebook',
      url: 'https://www.facebook.com/phoenixcontact',
      target: '_blank',
    },
    {
      text: 'Twitter',
      icon: 'twitter',
      url: 'https://twitter.com/PhoenixContactV',
      target: '_blank',
    },
    {
      text: 'YouTube',
      icon: 'youtube',
      url: 'https://www.youtube.com/channel/UC0w4cHMm7dy6UTjduwA1H6w/featured',
      target: '_blank',
    },
    {
      text: 'LinkedIn',
      icon: 'linkedin',
      url: 'https://www.linkedin.com/showcase/gebäudemanagement/?viewAsMember=true',
      target: '_blank',
    },
  ];
};

export let getVersionLinks = (): FooterLink[] => {
  return [];
};

export function setFooterLinksFn(fn: typeof getFooterLinks) {
  getFooterLinks = fn;
}

export function setVersionLinkFn(fn: typeof getFooterLinks) {
  getVersionLinks = fn;
}

export const Footer: FC = React.memo(() => {
  const links = getFooterLinks().concat(getVersionLinks());

  return (
    <footer className="footer">
      <div className="text-center">
        <ul>
          {links.map(link => (
            <li key={link.text}>
              <a href={link.url} target={link.target} rel="noopener">
                <Icon name={link.icon as IconName} /> {link.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
});
